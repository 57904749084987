import React, { useState } from "react";
import "./App.css";

function App() {
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const [error, setError] = useState("");

  const handleDeleteClick = () => {
    setShowConfirmBox(true);
    setError("");
  };

  const handleConfirmChange = (e) => {
    setConfirmationText(e.target.value);
  };

  const handleDeleteAccount = () => {
    if (confirmationText.toLowerCase() === "confirm") {
      alert("Your account has been successfully deleted.");
      setShowConfirmBox(false);
      setConfirmationText("");
    } else {
      setError("Please enter 'confirm' to delete your account.");
    }
  };

  return (
    <div className="main">
      <header className="hero-section">
        <h1>Manage Your Account</h1>
        <p className="hero-text">
          We're sorry to see you go. You can easily delete your Handle account by following the steps below.
        </p>
      </header>

      <div className="card">
        <h2>Delete Your Account</h2>
        <p className="info-text">
          Please follow these steps to delete your account and associated data from Handle. Ensure you understand the implications before proceeding.
        </p>

        <ul className="steps-list">
          <li>Go to the settings section of the Handle app.</li>
          <li>Click on the <strong>Delete Account</strong> option.</li>
          <li>Enter 'confirm' to permanently delete your account.</li>
        </ul>

        {/* {showConfirmBox && (
          <div className="confirm-box">
            <h3>Confirm Deletion</h3>
            <p className="confirm-text">
              Type 'confirm' below to permanently delete your account.
            </p>
            <input
              type="text"
              value={confirmationText}
              onChange={handleConfirmChange}
              placeholder="Type 'confirm'"
              className="input-box"
            />
            <button className="danger-button" onClick={handleDeleteAccount}>
              Confirm and Delete
            </button>
            {error && <p className="error-message">{error}</p>}
          </div>
        )} */}
      </div>

      <footer className="footer">
        <p>&copy; 2024 Handle App. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
